import React, { useState, useEffect } from "react";
import { theme } from "../constants";
import { hooks } from "../hooks";
import { actions } from "../store/actions";

const renderMinusSvg = () => (
  <svg width={14} height={14} fill="none">
    <path
      stroke="#23374A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M2.898 7h8.114"
    />
  </svg>
);

const renderPlusSvg = () => (
  <svg width={14} height={14} fill="none">
    <path
      stroke="#23374A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M6.955 2.917v8.166M2.898 7h8.114"
    />
  </svg>
);

export const ProductCounterInner = ({ item }) => {
  const dispatch = hooks.useAppDispatch();
  const cart = hooks.useAppSelector((state) => state.cartSlice.list);

  const quantityInCart = (item, cart) => {
    const ifItemInCart = cart.find((el) => el.id === item.id);
    return ifItemInCart && ifItemInCart.quantity ? ifItemInCart.quantity : 0;
  };

  const [quantity, setQuantity] = useState(quantityInCart(item, cart));

  useEffect(() => {
    setQuantity(quantityInCart(item, cart)); // Sync state with cart
  }, [cart, item]);

  const handleQuantityChange = (e) => {
    const value = Math.max(0, Number(e.target.value)); // Ensure the value is not negative
    setQuantity(value);
    dispatch(actions.addToCart({ ...item, quantity: value }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 1,
        borderRadius: 12,
        borderColor: "#E8EFF4",
        border: "solid 1px #E8EFF4",
      }}
    >
      <button
        onClick={() => {
          const newQuantity = Math.max(0, quantity - 1);
          setQuantity(newQuantity);
          dispatch(actions.addToCart({ ...item, quantity: newQuantity }));
        }}
        style={{
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 13,
          paddingBottom: 13,
          marginRight: 10,
          borderRadius: 12,
        }}
      >
        {renderMinusSvg()}
      </button>
      <input
        type="number"
        value={quantity}
        onChange={handleQuantityChange}
        style={{
          width: 40,
          textAlign: "center",
          ...theme.fonts.Mulish_600SemiBold,
          fontSize: 14,
          color: theme.colors.textColor,
          lineHeight: 1.5,
          marginBottom: 2,
          border: "none",
        }}
      />
      <button
        onClick={() => {
          const newQuantity = quantity + 1;
          setQuantity(newQuantity);
          dispatch(actions.addToCart({ ...item, quantity: newQuantity }));
        }}
        style={{
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 13,
          paddingBottom: 13,
          marginLeft: 10,
          borderRadius: 12,
        }}
      >
        {renderPlusSvg()}
      </button>
    </div>
  );
};
