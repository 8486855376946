import axios from "axios";
import { useMemo, useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { URLS } from "../../config";
import { hooks } from "../../hooks";
import { items } from "../../items";
import { custom } from "../../custom";
import { theme } from "../../constants";
import { actions } from "../../store/actions";
import { components } from "../../components";
import { svg } from "../../assets/svg";
import { useLocation } from "react-router-dom";

export const Search = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();
  const { list } = hooks.useAppSelector((state) => state.cartSlice);
  const location = useLocation();
  const categoryProducts = location?.state?.products?.items;

  const backgroundImages = [
    "linear-gradient(to right, #02AAB0 0%, #00CDAC 51%, #02AAB0 100%)",
    "linear-gradient(to right, #FFD319 0%, #FF8A00 51%, #FFD319 100%)",
    "linear-gradient(to right, #C9D6FF 0%, #E2E2E2 51%, #C9D6FF 100%)",
    "linear-gradient(to right, #FF6F61 0%, #D4A5A5 51%, #FF6F61 100%)",
    "linear-gradient(to right, #24C6DC 0%, #514A9D 51%, #24C6DC 100%)",
    "linear-gradient(to right, #F5A623 0%, #F5A623 51%, #F5A623 100%)",
    "linear-gradient(to right, #6DD5FA 0%, #2980B9 51%, #6DD5FA 100%)",
    "linear-gradient(to right, #FDC830 0%, #F37335 51%, #FDC830 100%)",
    "linear-gradient(to right, #00F260 0%, #0575E6 51%, #00F260 100%)",
    "linear-gradient(to right, #FF4B2B 0%, #FF416C 51%, #FF4B2B 100%)",
    "linear-gradient(to right, #FFB6C1 0%, #FF6F61 51%, #FFB6C1 100%)",
    "linear-gradient(to right, #E96443 0%, #904E95 51%, #E96443 100%)",
    "linear-gradient(to right, #FF9A8B 0%, #FAD0C4 51%, #FF9A8B 100%)",
    "linear-gradient(to right, #FFC3A0 0%, #FF677D 51%, #FFC3A0 100%)",
    "linear-gradient(to right, #6A82FB 0%, #FC5C7D 51%, #6A82FB 100%)",
    "linear-gradient(to right, #00C9FF 0%, #92FE9D 51%, #00C9FF 100%)",
    "linear-gradient(to right, #6F86D6 0%, #48C6EF 51%, #6F86D6 100%)",
    "linear-gradient(to right, #FF5F6D 0%, #FFC371 51%, #FF5F6D 100%)",
    "linear-gradient(to right, #DCE35B 0%, #45A247 51%, #DCE35B 100%)",
    "linear-gradient(to right, #C3E0E5 0%, #F7A6A0 51%, #C3E0E5 100%)",
    "linear-gradient(to right, #FF8C00 0%, #FF8C00 51%, #FF8C00 100%)",
    "linear-gradient(to right, #6E8B3D 0%, #3C5D3F 51%, #6E8B3D 100%)",
    "linear-gradient(to right, #B7F8DB 0%, #50A7C2 51%, #B7F8DB 100%)",
    "linear-gradient(to right, #3B3B6D 0%, #9D4A6C 51%, #3B3B6D 100%)",
    "linear-gradient(to right, #C9D6FF 0%, #E2E2E2 51%, #C9D6FF 100%)",
    "linear-gradient(to right, #FDC830 0%, #F37335 51%, #FDC830 100%)",
    "linear-gradient(to right, #FF6F61 0%, #D4A5A5 51%, #FF6F61 100%)",
    "linear-gradient(to right, #FF4B2B 0%, #FF416C 51%, #FF4B2B 100%)",
    "linear-gradient(to right, #F5A623 0%, #F5A623 51%, #F5A623 100%)",
    "linear-gradient(to right, #24C6DC 0%, #514A9D 51%, #24C6DC 100%)",
    "linear-gradient(to right, #C9D6FF 0%, #E2E2E2 51%, #C9D6FF 100%)",
    "linear-gradient(to right, #6DD5FA 0%, #2980B9 51%, #6DD5FA 100%)",
    "linear-gradient(to right, #FFC3A0 0%, #FF677D 51%, #FFC3A0 100%)",
    "linear-gradient(to right, #FF9A8B 0%, #FAD0C4 51%, #FF9A8B 100%)",
    "linear-gradient(to right, #FFB6C1 0%, #FF6F61 51%, #FFB6C1 100%)",
    "linear-gradient(to right, #FF8C00 0%, #FF8C00 51%, #FF8C00 100%)",
    "linear-gradient(to right, #FF5F6D 0%, #FFC371 51%, #FF5F6D 100%)",
    "linear-gradient(to right, #DCE35B 0%, #45A247 51%, #DCE35B 100%)",
    "linear-gradient(to right, #6F86D6 0%, #48C6EF 51%, #6F86D6 100%)",
    "linear-gradient(to right, #C3E0E5 0%, #F7A6A0 51%, #C3E0E5 100%)",
    "linear-gradient(to right, #B7F8DB 0%, #50A7C2 51%, #B7F8DB 100%)",
    "linear-gradient(to right, #6E8B3D 0%, #3C5D3F 51%, #6E8B3D 100%)",
    "linear-gradient(to right, #3B3B6D 0%, #9D4A6C 51%, #3B3B6D 100%)",
    "linear-gradient(to right, #DCE35B 0%, #45A247 51%, #DCE35B 100%)",
    "linear-gradient(to right, #00C9FF 0%, #92FE9D 51%, #00C9FF 100%)",
    "linear-gradient(to right, #FF8C00 0%, #FF8C00 51%, #FF8C00 100%)",
    "linear-gradient(to right, #FF5F6D 0%, #FFC371 51%, #FF5F6D 100%)",
    "linear-gradient(to right, #6F86D6 0%, #48C6EF 51%, #6F86D6 100%)",
    "linear-gradient(to right, #F5A623 0%, #F5A623 51%, #F5A623 100%)",
    "linear-gradient(to right, #6DD5FA 0%, #2980B9 51%, #6DD5FA 100%)",
    "linear-gradient(to right, #C9D6FF 0%, #E2E2E2 51%, #C9D6FF 100%)",
    "linear-gradient(to right, #FFB6C1 0%, #FF6F61 51%, #FFB6C1 100%)",
  ];

  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const getData = async () => {
    const storedUserId = localStorage.getItem("userId");
    try {
      const products = await axios
        .get(`${URLS.GET_PRODUCTS}?user_id=${storedUserId}`)
        .then((res) => res?.data?.item_list);
      setProductsData(products);
    } catch (error) {
      console.error(error);
    }
  };

  const getProductListWithQuantities = (productData, list) => {
    return productData.map((product) => {
      const updatedItems =
        product.items?.map((item) => {
          const cartItem = list.find((cart) => cart.id === item.id);
          return {
            ...item,
            quantity: cartItem ? cartItem.quantity : 0,
          };
        }) || [];

      return {
        ...product,
        items: updatedItems,
      };
    });
  };

  const productsWithQuantities = useMemo(
    () => getProductListWithQuantities(productsData, list),
    [productsData, list]
  );
  console.log("productsWithQuantities: ", productsWithQuantities);

  const allProducts = useMemo(
    () => productsWithQuantities.flatMap((category) => category.items),
    [productsWithQuantities]
  );

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const filtered =
      searchText === ""
        ? allProducts
        : allProducts.filter((product) =>
            product.item_name.toLowerCase().includes(searchText.toLowerCase())
          );
    setFilteredProducts(filtered);
  }, [searchText, allProducts]);

  const renderHeader = () => (
    <components.HeaderSearch
      goBack={true}
      title="Search"
      burger={false}
      basket={true}
      line={true}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );

  const renderCategory = () => (
    <div
      style={{
        padding: "0 15px 0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: 20,
      }}
    >
      {productsWithQuantities.map((elem, index) => (
        // {[...Array(10)].map((elem, index) => (
        <div
          key={index}
          style={{
            margin: "10px 5px",
          }}
          onClick={() => {
            dispatch(actions.resetFilters());
            navigate("/Shop", {
              state: { products: elem, title: elem?.category_name },
            });
          }}
        >
          <div
            style={{
              backgroundImage:
                backgroundImages[index % backgroundImages.length],
              padding: "10px 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "rgba(0, 0, 254, 0.1) 0px 3px 8px",
              borderRadius: 25,
            }}
          >
            <h5 style={{ fontWeight: 600, color: "#222" }}>
              {elem?.category_name}
            </h5>
          </div>
        </div>
      ))}
    </div>
  );

  const renderBestSellers = () => (
    <div
      style={{
        marginBottom: 20,
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {filteredProducts.map((item, index, arra) => (
        <items.ProductCard
          key={item.id}
          isLast={index === arra.length - 1}
          item={item}
          version={1}
        />
      ))}
    </div>
  );

  const renderContent = () => {
    if (loading) return <components.TabLoader />;

    return (
      <main
        style={{ paddingBottom: 64, display: "flex", flexDirection: "column" }}
      >
        {searchText === "" ? (
          <>
            {renderCategory()}
            {renderBestSellers()}
          </>
        ) : (
          renderBestSellers()
        )}
      </main>
    );
  };

  const renderBottomTabBar = () =>
    list?.length > 0 ? <components.BottomTabBar /> : null;

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderBottomTabBar()}
    </>
  );
};
