import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  list: [],
  delivery: 0,
  discount: 0,
  subtotal: 0,
  promoCode: "",
  discountAmount: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const inCart = state.list.find((item) => item.id === action.payload.id);

      if (inCart) {
        // Update quantity of existing item
        state.list = state.list.map((item) => {
          if (item.id === action.payload.id) {
            item.quantity = action.payload.quantity; // Update to the provided quantity
          }
          return item;
        });
      } else {
        // Add new item
        state.list.push({
          ...action.payload,
          quantity: action.payload.quantity,
        });
      }

      // Recalculate totals
      state.subtotal = state.list.reduce(
        (total, item) => total + Number(item.price) * item.quantity,
        0
      );
      state.total = state.subtotal * (1 - state.discount / 100);
      state.discountAmount = Number((state.subtotal - state.total).toFixed(2));
    },
    removeFromCart: (state, action) => {
      state.list = state.list.filter((item) => {
        if (item.id === action.payload.id) {
          if (item.quantity > 1) {
            item.quantity -= 1;
            state.subtotal -= Number(item.price);
            state.total -= Number(item.price) * (1 - state.discount / 100);
            return true;
          } else {
            state.subtotal -= Number(item.price);
            state.total -= Number(item.price) * (1 - state.discount / 100);
            return false;
          }
        }
        return true;
      });

      if (state.list.length === 0) {
        state.discount = 0;
        state.promoCode = "";
      }
    },
    setDiscount: (state, action) => {
      if (state.list.length === 0) {
        state.discount = 0;
      } else {
        state.discount = action.payload;
      }
      const newTotal = state.subtotal * (1 - state.discount / 100);
      state.discountAmount = Number((state.subtotal - newTotal).toFixed(2));
      state.total = state.subtotal * (1 - state.discount / 100);
    },
    resetCart: (state) => {
      state.list = [];
      state.subtotal = 0;
      state.total = 0;
      state.discount = 0;
      state.promoCode = "";
      state.delivery = 0;
      state.discountAmount = 0;
    },
    setPromoCode: (state, action) => {
      state.promoCode = action.payload;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  setDiscount,
  resetCart,
  setPromoCode,
} = cartSlice.actions;

export default cartSlice.reducer;
