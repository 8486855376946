import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { URLS } from "../../config";
import { hooks } from "../../hooks";
import { items } from "../../items";
import { custom } from "../../custom";
import { theme } from "../../constants";
import { actions } from "../../store/actions";
import { components } from "../../components";

export const Home = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();
  const { list } = hooks.useAppSelector((state) => state.cartSlice);

  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [productsData, setProductsData] = useState([]);
  const [bannersData, setBannersData] = useState([]);
  const [carouselData, setCarouselData] = useState([]);
  useEffect(() => {
    const url = window.location.href; // Get the current URL
    const base64Id = url.split("/").pop(); // Extract the base64 ID from the URL
    const decodedString = atob(base64Id); // Decode the base64 string
    const userIdNumber = parseInt(decodedString, 10); // Convert the decoded string to a number
    localStorage.setItem("userId", userIdNumber.toString());
    localStorage.setItem("base64Id_userId", base64Id.toString());
  }, []);

  const getData = async () => {
    const storedUserId = localStorage.getItem("userId");
    setLoading(true);
    try {
      const products = await axios
        .get(`${URLS.GET_PRODUCTS}?user_id=${storedUserId}`)
        .then((res) => res?.data?.item_list);
      setProductsData(products);
      // console.log("products: ", products);

      // const banners = await axios
      //   .get(URLS.GET_BANNERS)
      //   .then((res) => res.data.banners);

      // Fetch carousel data using POST method
      const carousel = await axios
        .post(URLS.GET_CAROUSEL, { user_id: storedUserId })
        .then((res) => res.data?.data);
      setCarouselData(carousel);

      // setBannersData(banners);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // console.log("carouselData: ", carouselData);

  // Function to merge product list with cart quantities
  const getProductListWithQuantities = (productData, list) => {
    return productData.map((product) => {
      const updatedItems =
        product.items?.map((item) => {
          const cartItem = list.find((cart) => cart.id === item.id);
          return {
            ...item,
            quantity: cartItem ? cartItem.quantity : 0,
          };
        }) || [];

      return {
        ...product,
        items: updatedItems,
      };
    });
  };

  const productsWithQuantities = getProductListWithQuantities(
    productsData,
    list
  );

  // console.log("productsWithQuantities: ", productsWithQuantities);

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  const renderHeader = () => {
    return (
      <components.Header
        burger={true}
        basket={true}
        line={true}
        searchBar={true}
      />
    );
  };
  const renderCarousel = () => {
    return (
      <div style={{ marginBottom: 22 }} data-aos="fade">
        <Carousel
          infiniteLoop={false}
          showStatus={false}
          showThumbs={false}
          thumbWidth={22}
          showIndicators={false}
          showArrows={false}
          onChange={handleSlideChange}
          swipeable={true}
          emulateTouch={true}
        >
          {carouselData?.map((item, index) => {
            return (
              <img
                key={index}
                src={item?.desktop_banner}
                alt="Carousel"
                style={{
                  width: "100%",
                  height: 300,
                  objectFit: "contain",
                  backgroundColor: theme.colors.imageBackground,
                }}
              />
            );
          })}
        </Carousel>
      </div>
    );
  };

  const renderIndicator = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 40,
        }}
      >
        {carouselData?.map((item, index) => {
          const isSelected = index === activeIndex;
          const indicatorStyle = {
            background: isSelected ? theme.colors.mainColor : "#E8EFF4",
            display: "inline-block",
            width: 22,
            height: 6,
            margin: "0 6px",
            borderRadius: 6,
          };
          return <div style={indicatorStyle} key={index} />;
        })}
      </div>
    );
  };

  const renderBestSellers = () => {
    // Filter categories to show only those with products
    const filteredCategories = productsWithQuantities?.filter(
      (ele) => ele?.items?.length > 0
    );

    return filteredCategories?.map((ele, index) => {
      return (
        <div
          key={index}
          style={{ marginBottom: 40, display: "flex", flexDirection: "column" }}
        >
          <components.BlockHeading
            title={ele?.category_name}
            containerStyle={{
              padding: "0 20px 0",
              marginBottom: 14,
            }}
            viewAllOnClick={() => {
              dispatch(actions.resetFilters());
              navigate("/Shop", {
                state: {
                  products: ele,
                  title: ele?.category_name,
                },
              });
            }}
          />
          <div>
            {ele?.items?.slice(0, 5)?.map((item, index, arra) => {
              const isLast = index === arra.length - 1;
              return (
                <items.ProductCard
                  key={item.id}
                  isLast={isLast}
                  item={item}
                  version={1}
                />
              );
            })}
          </div>
        </div>
      );
    });
  };

  const renderContent = () => {
    if (loading) return <components.TabLoader />;

    return (
      <main
        style={{
          paddingBottom: 64,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {renderCarousel()}
        {renderIndicator()}
        {renderBestSellers()}
      </main>
    );
  };

  const renderBottomTabBar = () => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {list?.length > 0 ? renderBottomTabBar() : null}
    </>
  );
};
