import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

import { URLS } from "../../config";
import { hooks } from "../../hooks";
import { items } from "../../items";
import { custom } from "../../custom";
import { theme } from "../../constants";
import { actions } from "../../store/actions";
import { components } from "../../components";
import { svg } from "../../assets/svg";
import { useLocation } from "react-router-dom";

export const Shop = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();
  const { list } = hooks.useAppSelector((state) => state.cartSlice);
  const location = useLocation();
  const title = location?.state?.title;
  const categoryProducts = location?.state?.products?.items;

  const [loading, setLoading] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);

  const getProductListWithQuantities = (productData, list) => {
    return productData.map((product) => {
      const cartItem = list.find((cart) => cart.id === product.id);
      return {
        ...product,
        quantity: cartItem?.quantity,
      };
    });
  };

  const productsWithQuantities = getProductListWithQuantities(
    categoryProducts,
    list
  );

  console.log("productsWithQuantities: ", title);

  useEffect(() => {
    // getData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  const handleOnClick = () => {
    if (cart.length > 0) {
      dispatch(actions.setScreen("Order"));
      navigate("/TabNavigator");
      return;
    }

    return alert("Your cart is empty");
  };

  const cart = hooks.useAppSelector((state) => state.cartSlice.list);

  const renderHeader = () => {
    return (
      <components.Header
        goBack={true}
        title={title}
        burger={false}
        basket={true}
        line={true}
      />
    );
  };

  const renderBestSellers = () => {
    return (
      <div
        style={{
          marginBottom: 40,
          marginTop: 40,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          {productsWithQuantities?.map((item, index, arra) => {
            const isLast = index === arra.length - 1;
            return (
              <items.ProductCard
                key={item.id}
                isLast={isLast}
                item={item}
                version={1}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (loading) return <components.TabLoader />;

    return (
      <main
        style={{
          paddingBottom: 64,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {renderBestSellers()}
      </main>
    );
  };

  const renderBottomTabBar = () => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {list?.length > 0 ? renderBottomTabBar() : null}
    </>
  );
};
