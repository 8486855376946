import React, { useState } from "react";
import { hooks } from "../hooks";
import { theme } from "../constants";
import { actions } from "../store/actions";

export const ProductCounter = ({ item }) => {
  const dispatch = hooks.useAppDispatch();
  const [quantity, setQuantity] = useState(item.quantity || 0);

  const btnStyle = {
    width: 30,
    height: 30,
    borderWidth: 1,
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
    borderColor: theme.colors.aliceBlue2,
  };

  const handleQuantityChange = (e) => {
    const value = Math.max(0, Number(e.target.value)); // Ensure the value is not negative
    setQuantity(value);
    dispatch(actions.addToCart({ ...item, quantity: value }));
  };

  const addToCartButton = () => (
    <button
      style={{ ...btnStyle }}
      onClick={() => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        dispatch(actions.addToCart({ ...item, quantity: newQuantity }));
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
      >
        <rect
          width={29}
          height={29}
          x={0.5}
          y={0.5}
          stroke="#E8EFF4"
          rx={14.5}
        />
        <path
          stroke="#193364"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
          d="M14.955 10.916v8.167M10.898 15h8.114"
        />
      </svg>
    </button>
  );

  const removeFromCartButton = () => (
    <button
      data-aos="fade"
      style={{ ...btnStyle }}
      onClick={() => {
        if (quantity > 0) {
          const newQuantity = quantity - 1;
          setQuantity(newQuantity);
          if (newQuantity > 0) {
            dispatch(actions.addToCart({ ...item, quantity: newQuantity }));
          } else {
            dispatch(actions.removeFromCart(item));
          }
        }
      }}
    >
      <svg width={30} height={30} fill="none">
        <rect
          width={29}
          height={29}
          x={0.5}
          y={0.5}
          stroke="#E8EFF4"
          rx={14.5}
        />
        <path
          stroke="#193364"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
          d="M11 15h8.114"
        />
      </svg>
    </button>
  );

  const renderQty = () => (
    <input
      type="number"
      value={quantity}
      onChange={handleQuantityChange}
      style={{
        width: 40,
        textAlign: "center",
        ...theme.fonts.Mulish_400Regular,
        fontSize: 14,
        color: theme.colors.textColor,
        lineHeight: 1.5,
        marginBottom: 2,
        border: "none",
      }}
    />
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {addToCartButton()}
      {item.quantity > 0 ? renderQty() : null}
      {item.quantity > 0 ? removeFromCartButton() : null}
    </div>
  );
};
