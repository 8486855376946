import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";
import axios from "axios";
import { hooks } from "../hooks";
import { svg } from "../assets/svg";
import { theme } from "../constants";
import { components } from ".";
import { actions } from "../store/actions";
import { MAIN_URL, URLS } from "../config";

export const Header = ({ line, title, burger, goBack, basket, searchBar }) => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null);
  const [productsData, setProductsData] = useState([]);

  const getData = async () => {
    const storedUserId = localStorage.getItem("userId");
    try {
      const userData = await axios
        .get(`${URLS.GET_COMPANY}?user_id=${storedUserId}`)
        .then((res) => res?.data?.user_data);
      setCompanyData(userData);

      const products = await axios
        .get(`${URLS.GET_PRODUCTS}?user_id=${storedUserId}`)
        .then((res) => res?.data?.item_list);
      setProductsData(products);
    } catch (error) {
      console.error(error);
    }
  };

  const parts = [];
  for (let i = 0; i < companyData?.companyaddress?.length; i += 35) {
    parts.push(companyData?.companyaddress?.slice(i, i + 35));
  }

  useEffect(() => {
    getData();
  }, []);

  const { list } = hooks.useAppSelector((state) => state.cartSlice);

  const totalQuantity = list.reduce((total, product) => {
    return total + product.quantity;
  }, 0);
  const [modal, setModal] = useState(false);

  const backdropAnimation = useSpring({
    opacity: modal ? 1 : 0,
    display: modal ? "block" : "none",
  });

  const dispatch = hooks.useAppDispatch();

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
    // document.body.style.overflow = 'hidden';
  }, [modal]);

  const cart = hooks.useAppSelector((state) => state.cartSlice.list);
  const total = hooks.useAppSelector((state) => state.cartSlice.total);

  const getProductListWithQuantities = (productData, list) => {
    return productData.map((product) => {
      const updatedItems =
        product.items?.map((item) => {
          const cartItem = list.find((cart) => cart.id === item.id);
          return {
            ...item,
            quantity: cartItem ? cartItem.quantity : 0,
          };
        }) || [];

      return {
        ...product,
        items: updatedItems,
      };
    });
  };

  const productsWithQuantities = getProductListWithQuantities(
    productsData,
    cart
  );

  const handleOnClick = () => {
    if (cart.length > 0) {
      dispatch(actions.setScreen("Order"));
      navigate("/order");
      return;
    }

    return alert("Your cart is empty");
  };

  const renderGoBack = () => {
    if (goBack) {
      return (
        <button
          style={{
            position: "absolute",
            left: 0,
            padding: 20,
            cursor: "pointer",
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={() => navigate(-1)}
        >
          <svg.GoBackSvg />
        </button>
      );
    }

    return null;
  };

  const renderBurger = () => {
    if (burger) {
      return (
        <button
          style={{
            position: "absolute",
            left: 0,
            padding: 20,
            cursor: "pointer",
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={() => setModal(true)}
        >
          <svg.BurgerSvg />
        </button>
      );
    }

    return null;
  };

  const renderTitle = () => {
    return (
      <h4
        style={{
          width: "calc(100% - 100px)",
          textAlign: "center",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          ...theme.fonts.Mulish_600SemiBold,
          fontSize: 18,
          color: theme.colors.mainColor,
        }}
      >
        {title || companyData?.company_name}
        {/* {companyData?.company_name} */}
      </h4>
    );
  };

  const renderSearch = () => {
    if (searchBar) {
      return (
        <button
          onClick={() => {
            dispatch(actions.resetFilters());
            navigate("/Search", {
              state: {
                products: productsWithQuantities,
              },
            });
          }}
          style={{
            position: "absolute",
            right: 50,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 12,
          }}
        >
          <svg.SearchSvg />
        </button>
      );
    }

    return null;
  };
  const renderBasket = () => {
    if (basket) {
      return (
        <button
          onClick={handleOnClick}
          style={{
            position: "absolute",
            right: 0,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 12,
          }}
        >
          <svg.BasketSvg />
          <div
            style={{
              paddingLeft: 6,
              paddingRight: 6,
              borderRadius: 10,
              right: 35,
              bottom: 10,
              position: "absolute",
              display: "flex",
              paddingTop: 2,
              paddingBottom: 2,
              fontSize: 10,
              color: theme.colors.white,
              ...theme.fonts.Mulish_700Bold,
              backgroundColor: theme.colors.coralRed,
            }}
          >
            {totalQuantity > 0 ? `${totalQuantity}` : "0"}
          </div>
        </button>
      );
    }

    return null;
  };

  const renderModal = () => {
    return (
      <animated.div
        className={`${modal ? "slide-in container" : "container"}`}
        style={{
          position: "fixed",
          height: "100%",
          zIndex: 999999,
          backgroundColor: theme.colors.mainColor,
          ...backdropAnimation,
          paddingTop: 60,
        }}
      >
        {/* <div
          style={{
            width: 2,
            backgroundColor: theme.colors.white,
            height: 30,
            borderRadius: 10,
            margin: "0 20px",
            marginBottom: 14,
          }}
        /> */}
        {companyData?.image && (
          <div
            style={{
              display: "flex",
            }}
          >
            <img
              style={{ marginBottom: 20, objectFit: "contain" }}
              width={200}
              height={150}
              src={MAIN_URL + "/" + companyData?.image}
              alt="logo"
            />
          </div>
        )}

        <h2
          style={{
            padding: "0 20px",
            ...theme.fonts.Mulish_700Bold,
            fontSize: 22,
            textTransform: "capitalize",
            marginBottom: 30,
            textAlign: "left",
          }}
        >
          Contact us
        </h2>
        <components.BurgerMenuItem
          titleLine1={parts}
          // titleLine2="700039"
          icon={<svg.MapPinSvg />}
        />
        <components.BurgerMenuItem
          titleLine2={companyData?.email_id}
          // titleLine2="stephubsupport@mail.com"
          icon={<svg.ModalMailSvg />}
        />
        <components.BurgerMenuItem
          titleLine2={companyData?.phone_number}
          titleLine3={companyData?.alt_phone_number}
          icon={<svg.PhoneCallSvg />}
        />
      </animated.div>
    );
  };

  const renderOverlay = () => {
    return (
      <animated.div
        className="container"
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(25, 51, 100, 0.6)",
          position: "fixed",
          inset: 0,
          zIndex: 99999,
          overflow: "hidden",
          ...backdropAnimation,
        }}
        onClick={() => setModal(false)}
      />
    );
  };

  return (
    <>
      {renderOverlay()}
      {renderModal()}
      <header
        style={{
          top: 0,
          height: 52,
          position: "sticky",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: theme.colors.white,
          zIndex: 4,
          borderBottom: line ? `1px solid ${theme.colors.aliceBlue2}` : "none",
        }}
      >
        {renderGoBack()}
        {renderBurger()}
        {renderTitle()}
        {renderSearch()}
        {renderBasket()}
      </header>
    </>
  );
};
