import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";
import axios from "axios";
import { hooks } from "../hooks";
import { svg } from "../assets/svg";
import { theme } from "../constants";
import { components } from ".";
import { actions } from "../store/actions";
import { URLS } from "../config";

export const HeaderSearch = ({
  line,
  burger,
  goBack,
  basket,
  setSearchText,
  searchText,
}) => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null);
  const [productsData, setProductsData] = useState([]);

  const getData = async () => {
    const storedUserId = localStorage.getItem("userId");
    try {
      const userData = await axios
        .get(`${URLS.GET_COMPANY}?user_id=${storedUserId}`)
        .then((res) => res?.data?.user_data);
      setCompanyData(userData);

      const products = await axios
        .get(`${URLS.GET_PRODUCTS}?user_id=${storedUserId}`)
        .then((res) => res?.data?.item_list);
      setProductsData(products);
    } catch (error) {
      console.error(error);
    }
  };

  const parts = [];
  for (let i = 0; i < companyData?.companyaddress?.length; i += 35) {
    parts.push(companyData?.companyaddress?.slice(i, i + 35));
  }

  useEffect(() => {
    getData();
  }, []);

  const { list } = hooks.useAppSelector((state) => state.cartSlice);

  const totalQuantity = list.reduce((total, product) => {
    return total + product.quantity;
  }, 0);

  const dispatch = hooks.useAppDispatch();

  const cart = hooks.useAppSelector((state) => state.cartSlice.list);

  const handleOnClick = () => {
    if (cart.length > 0) {
      dispatch(actions.setScreen("Order"));
      navigate("/order");
      return;
    }

    return alert("Your cart is empty");
  };

  const renderGoBack = () => {
    if (goBack) {
      return (
        <button
          style={{
            position: "absolute",
            left: 0,
            padding: 20,
            cursor: "pointer",
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={() => navigate(-1)}
        >
          <svg.GoBackSvg />
        </button>
      );
    }

    return null;
  };

  const renderTitle = () => {
    return (
      <div
        style={{
          width: "calc(100% - 100px)",
          textAlign: "center",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          ...theme.fonts.Mulish_600SemiBold,
          fontSize: 18,
          color: theme.colors.mainColor,
        }}
      >
        <input
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "25px",
            width: "90%",
          }}
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search by product"
        />
      </div>
    );
  };

  const renderBasket = () => {
    if (basket) {
      return (
        <button
          onClick={handleOnClick}
          style={{
            position: "absolute",
            right: 0,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 12,
          }}
        >
          <svg.BasketSvg />
          <div
            style={{
              paddingLeft: 6,
              paddingRight: 6,
              borderRadius: 10,
              right: 35,
              bottom: 10,
              position: "absolute",
              display: "flex",
              paddingTop: 2,
              paddingBottom: 2,
              fontSize: 10,
              color: theme.colors.white,
              ...theme.fonts.Mulish_700Bold,
              backgroundColor: theme.colors.coralRed,
            }}
          >
            {totalQuantity > 0 ? `${totalQuantity}` : "0"}
          </div>
        </button>
      );
    }

    return null;
  };

  return (
    <>
      <header
        style={{
          top: 0,
          height: 52,
          position: "sticky",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: theme.colors.white,
          zIndex: 4,
          borderBottom: line ? `1px solid ${theme.colors.aliceBlue2}` : "none",
        }}
      >
        {renderGoBack()}
        {renderTitle()}
        {renderBasket()}
      </header>
    </>
  );
};
