import React, { useState, useEffect } from "react";
import { hooks } from "../../hooks";
import { custom } from "../../custom";
import { svg } from "../../assets/svg";
import { components } from "../../components";

export const ShippingAndPaymentInfo = () => {
  const navigate = hooks.useAppNavigate();
  const { list } = hooks.useAppSelector((state) => state.cartSlice);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async () => {
    const storedUserId = localStorage.getItem("userId");
    const payload = {
      user_id: storedUserId,
      name: name,
      phone_no: phone,
      city: address, // Static field
      description: description, // Static field
      product_list: list,
    };

    if (name !== "" && phone !== "" && address !== "") {
      try {
        const response = await fetch(
          "https://catalogmakerpro.in/server/CatalogMakerPro/cart/customerInquiry_new.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();
        if (response.ok) {
          // Handle successful response
          navigate("/OrderSuccessful");
        } else {
          // Handle error response
          console.error("Error:", data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const renderHeader = () => {
    return <components.Header title="Enquiry Now" goBack={true} />;
  };

  const renderContent = () => {
    return (
      <div style={{ padding: "40px 20px 20px 20px" }}>
        <custom.InputField
          placeholder="enter your name"
          label="Name"
          icon={<svg.InputCheckSvg />}
          containerStyle={{ marginBottom: 20 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <custom.InputField
          placeholder="enter your phone number"
          label="Phone number"
          containerStyle={{
            marginBottom: 20,
          }}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <custom.InputField
          placeholder="enter your address"
          label="Address"
          containerStyle={{
            marginBottom: 20,
          }}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <custom.InputField
          placeholder="Wite a note"
          label="Note"
          type="text"
          containerStyle={{
            marginBottom: 20,
          }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <components.Button title="Submit" onClick={handleSubmit} />
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
