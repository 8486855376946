export const colors = {
  white: "#FFFFFF",
  coralRed: "#FF4040",
  darkBlue: "#000000",
  aliceBlue: "#F2F6FF",
  textColor: "#60708E",
  // mainColor: "#193364",
  mainColor: "#7c9885",
  aliceBlue2: "#E8EFF4",
  ghostWhite: "#F4F7FC",
  mainYellow: "#F5C102",
  ghostWhite2: "#EDF1FA",
  lavenderMist: "#DBE3F5",
  bodyTextColor: "#333333",
  antiFlashWhite: "#E7EBEB",
  imageBackground: "#F2F7FC",
  transparent: "transparent",
  secondaryTextColor: "#666666",
};

export const theme = { colors };
